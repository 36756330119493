.frame-mobile {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.frame-mobile .div {
  background-color: #ffffff;
  height: 3628px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.frame-mobile .overlap {
  height: 791px;
  left: 0;
  position: absolute;
  top: 2837px;
  width: 360px;
}

.frame-mobile .rectangle {
  background-color: var(--variable-collection-neutral-1);
  height: 623px;
  left: 0;
  position: absolute;
  top: 168px;
  width: 100%;
  margin-left: 15px;

}

.frame-mobile .s-n-ph-m-c-a-c-ng-ty {
  color: #ffffff;
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 26px;
  letter-spacing: 0;
  line-height: 25.1px;
  position: absolute;
  text-align: center;
  top: 514px;
}

.frame-mobile .text-wrapper {
  color: #ffffff;
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 90px;
  letter-spacing: 0;
  line-height: 25.1px;
  position: absolute;
  text-align: center;
  top: 575px;
  white-space: nowrap;
}

.frame-mobile .vector {
  height: 47px;
  left: 120px;
  position: absolute;
  top: 431px;
  width: 126px;
}

.frame-mobile .overlap-wrapper {
  background: radial-gradient(50% 50% at 50% 50%, rgb(255, 136.14, 78.63) 0%, rgb(255, 85, 76) 100%);
  border-radius: 42px;
  height: 394px;
  left: 55px;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 280px;
}

.frame-mobile .overlap-group {
  height: 1137px;
  left: -161px;
  position: relative;
  width: 954px;
}

.frame-mobile .rectangle-2 {
  background: radial-gradient(50% 50% at 50% 50%, rgb(255, 136.14, 78.63) 0%, rgb(255, 85, 76) 100%);
  border-radius: 42px;
  height: 625px;
  left: 161px;
  position: absolute;
  top: 0;
  width: 280px;
}

.frame-mobile .ellipse {
  background: linear-gradient(180deg, rgba(255, 153.08, 147.69, 0.04) 0%, rgba(255, 85, 76, 0) 100%);
  border-radius: 477px;
  box-shadow: inset 0px 130px 134px #ffffff26;
  height: 954px;
  left: 0;
  position: absolute;
  top: 183px;
  width: 954px;
}

.frame-mobile .vev-ngay-th-i-ch-chi {
  color: #ffffff;
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 30px;
  font-weight: 700;
  left: 193px;
  letter-spacing: 0;
  line-height: 47.1px;
  position: absolute;
  text-align: center;
  top: 44px;
}

.frame-mobile .appstore {
  height: 52px;
  left: 216px;
  position: absolute;
  top: 210px;
  width: 180px;
}

.frame-mobile .googleplay {
  height: 52px;
  left: 217px;
  position: absolute;
  top: 277px;
  width: 180px;
}

.frame-mobile .div-2 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  left: 93px;
  position: absolute;
  top: 637px;
  padding-bottom: 50px;
}

.frame-mobile .p {
  color: #ffffff;
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25.1px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame-mobile .text-wrapper-2 {
  color: #ffffff;
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25.1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame-mobile .text-wrapper-2  a{
  color: #ffffff;
}
.frame-mobile .overlap-2 {
  height: 804px;
  left: -76px;
  position: absolute;
  top: -362px;
  width: 814px;
}

.frame-mobile .ellipse-2 {
  background: radial-gradient(50% 50% at 50% 50%, rgb(255, 136.14, 78.63) 0%, rgb(255, 85, 76) 100%);
  border-radius: 407px/321.6px;
  height: 643px;
  left: 0;
  position: absolute;
  top: 0;
  width: 814px;
}

.frame-mobile .ellipse-3 {
  background-color: #ffffff1a;
  border-radius: 211.28px/256.94px;
  box-shadow: inset 0px -50.26px 21.35px #ffffff26;
  height: 514px;
  left: 167px;
  position: absolute;
  top: 106px;
  transform: rotate(119.36deg);
  width: 423px;
}

.frame-mobile .iphone-mini {
  background-color: var(--variable-collection-neutral-white);
  border-radius: 12.28px;
  box-shadow: 0px 13.98px 35.47px #ff8e8930;
  height: 283px;
  left: 261px;
  overflow: hidden;
  position: absolute;
  top: 512px;
  transform: rotate(10.47deg);
  width: 131px;
}

.frame-mobile .div-3 {
  align-items: flex-start;
  background-color: var(--variable-collection-neutral-white);
  border-color: #3a355533;
  border-top-style: solid;
  border-top-width: 0.35px;
  display: flex;
  gap: 6.96px;
  left: 0;
  padding: 3.48px 5.57px 11.83px;
  position: absolute;
  top: 248px;
  width: 131px;
}

.frame-mobile .div-4 {
  align-items: center;
  border-radius: 2.78px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 1.39px;
  padding: 2.78px 0px;
  position: relative;
}

.frame-mobile .icon-instance-node {
  height: 9.73px !important;
  margin-top: -0.69px !important;
  position: relative !important;
  transform: rotate(-10.47deg) !important;
  width: 9.73px !important;
}

.frame-mobile .text-wrapper-3 {
  color: var(--variable-collection-neutral-2);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 3.5px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame-mobile .div-5 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 1.39px;
  padding: 2.78px 0px;
  position: relative;
}

.frame-mobile .text-wrapper-4 {
  color: var(--variable-collection-brand-brand-primary);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 3.5px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame-mobile .text-wrapper-5 {
  color: var(--variable-collection-brand-brand-primary);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 7px;
  font-weight: 700;
  left: 6px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 20px;
}

.frame-mobile .text-wrapper-6 {
  color: var(--variable-collection-neutral-1);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 5.6px;
  font-weight: 500;
  left: 6px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 36px;
}

.frame-mobile .text-wrapper-7 {
  color: var(--variable-collection-neutral-1);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 5.6px;
  font-weight: 500;
  left: 6px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 123px;
}

.frame-mobile .div-6 {
  align-items: flex-start;
  background-color: #f6f6fa;
  border-radius: 4.18px;
  display: flex;
  flex-direction: column;
  gap: 2.78px;
  justify-content: flex-end;
  left: 6px;
  padding: 2.78px 2.78px 4.18px;
  position: absolute;
  top: 46px;
  width: 119px;
}

.frame-mobile .img {
  height: 31.07px;
  margin-left: -1.61px;
  margin-top: -1.61px;
  object-fit: cover;
  position: relative;
  transform: rotate(-10.47deg);
  width: 31.07px;
}

.frame-mobile .div-7 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4.18px;
  position: relative;
  width: 100%;
}

.frame-mobile .text-wrapper-8 {
  color: var(--variable-collection-neutral-1);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 6.3px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.35px;
  position: relative;
  width: 112.43px;
}

.frame-mobile .div-8 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 2.78px;
  position: relative;
  width: 100%;
}

.frame-mobile .div-9 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3.48px;
  padding: 0.7px 0px;
  position: relative;
}

.frame-mobile .icon-instance-node-2 {
  height: 4.06px !important;
  margin-bottom: -0.29px !important;
  margin-left: -0.29px !important;
  margin-right: -0.29px !important;
  margin-top: -0.29px !important;
  position: relative !important;
  transform: rotate(-10.47deg) !important;
  width: 4.06px !important;
}

.frame-mobile .text-wrapper-9 {
  color: var(--variable-collection-brand-brand-primary);
  flex: 1;
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 4.2px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.35px;
  position: relative;
}

.frame-mobile .text-wrapper-10 {
  color: var(--variable-collection-neutral-2);
  flex: 1;
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 4.2px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.35px;
  position: relative;
}

.frame-mobile .div-wrapper {
  align-items: center;
  background-color: var(--variable-collection-neutral-white);
  border-radius: 3.13px;
  box-shadow: 0px 0px 4.52px #ff645a1a;
  display: inline-flex;
  gap: 3.48px;
  height: 11px;
  justify-content: center;
  left: 61px;
  padding: 2.78px 3.48px;
  position: absolute;
  top: 3px;
}

.frame-mobile .text-wrapper-11 {
  color: var(--variable-collection-brand-brand-primary);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 4.2px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.06px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.frame-mobile .div-10 {
  align-items: center;
  background-color: var(--variable-collection-green-1);
  border-radius: 3.13px;
  box-shadow: 0px 0px 4.52px #ff645a1a;
  display: inline-flex;
  gap: 1.39px;
  justify-content: center;
  left: 78px;
  padding: 2.78px 3.48px;
  position: absolute;
  top: 3px;
}

.frame-mobile .icon-instance-node-3 {
  height: 6.49px !important;
  margin-bottom: -0.46px !important;
  margin-left: -0.46px !important;
  margin-top: -0.46px !important;
  position: relative !important;
  transform: rotate(-10.47deg) !important;
  width: 6.49px !important;
}

.frame-mobile .text-wrapper-12 {
  color: var(--variable-collection-neutral-white);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 4.2px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.06px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.frame-mobile .div-11 {
  align-items: flex-start;
  background-color: #f6f6fa;
  border-radius: 4.18px;
  display: flex;
  flex-direction: column;
  gap: 2.78px;
  justify-content: flex-end;
  left: 6px;
  padding: 2.78px 2.78px 4.18px;
  position: absolute;
  top: 133px;
  width: 119px;
}

.frame-mobile .div-wrapper-2 {
  align-items: center;
  background-color: var(--variable-collection-neutral-white);
  border-radius: 3.13px;
  box-shadow: 0px 0px 4.52px #ff645a1a;
  display: inline-flex;
  gap: 3.48px;
  height: 11px;
  justify-content: center;
  left: 53px;
  padding: 2.78px 3.48px;
  position: absolute;
  top: 3px;
}

.frame-mobile .div-12 {
  align-items: center;
  background-color: var(--variable-collection-brand-brand-primary);
  border-radius: 3.13px;
  box-shadow: 0px 0px 4.52px #ff645a1a;
  display: inline-flex;
  gap: 1.39px;
  height: 11px;
  justify-content: center;
  left: 71px;
  padding: 2.78px 3.48px;
  position: absolute;
  top: 3px;
}

.frame-mobile .bars-status-default {
  height: 15px;
  left: 0;
  position: absolute;
  top: 0;
  width: 131px;
}

.frame-mobile .action {
  height: 18px;
  left: 7px;
  position: absolute;
  top: 5px;
  width: 56px;
}

.frame-mobile .time {
  color: var(--variable-collection-neutral-1);
  font-family: "SF Pro Text-Semibold", Helvetica;
  font-size: 15px;
  font-weight: 400;
  left: 0;
  letter-spacing: -0.17px;
  line-height: 18px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 54px;
}

.frame-mobile .container {
  height: 4px;
  left: 102px;
  position: absolute;
  top: 6px;
  width: 23px;
}

.frame-mobile .battery {
  height: 4px;
  left: 15px;
  position: absolute;
  top: 0;
  width: 9px;
}

.frame-mobile .overlap-group-2 {
  height: 5px;
  position: relative;
  top: -1px;
}

.frame-mobile .rectangle-3 {
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 9px;
}

.frame-mobile .rectangle-4 {
  background-color: var(--variable-collection-neutral-1);
  border-radius: 0.56px;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 8px;
}

.frame-mobile .combined-shape {
  height: 4px;
  left: 0;
  position: absolute;
  top: -1px;
  width: 6px;
}

.frame-mobile .wi-fi {
  height: 4px;
  left: 8px;
  position: absolute;
  top: 0;
  width: 5px;
}

.frame-mobile .li-clock {
  height: 10px !important;
  left: 116px !important;
  position: absolute !important;
  top: 19px !important;
  transform: rotate(-10.47deg) !important;
  width: 10px !important;
}

.frame-mobile .iphone-mini-2 {
  background-color: var(--variable-collection-neutral-white);
  border-radius: 12.28px;
  box-shadow: 0px 13.98px 35.47px #ff8e8930;
  height: 277px;
  left: 128px;
  overflow: hidden;
  position: absolute;
  top: 472px;
  transform: rotate(-14.53deg);
  width: 128px;
}

.frame-mobile .text-wrapper-13 {
  color: var(--variable-collection-brand-brand-primary);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 6.8px;
  font-weight: 700;
  left: 5px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 33px;
}

.frame-mobile .div-13 {
  background-color: #e7e8f1;
  border-radius: 20.8px;
  height: 15px;
  left: 5px;
  position: absolute;
  top: 48px;
  width: 117px;
}

.frame-mobile .text-wrapper-14 {
  color: var(--variable-collection-neutral-1);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 4.8px;
  font-weight: 500;
  left: 46px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 4px;
}

.frame-mobile .div-14 {
  align-items: center;
  background-color: var(--variable-collection-neutral-white);
  border-radius: 20.8px;
  display: inline-flex;
  gap: 2.73px;
  height: 12px;
  left: 1px;
  padding: 0px 2.73px;
  position: absolute;
  top: 1px;
}

.frame-mobile .div-15 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 1.71px;
  position: relative;
}

.frame-mobile .icon-music {
  height: 6.65px !important;
  margin-bottom: -0.33px !important;
  margin-left: -0.6px !important;
  margin-top: -0.33px !important;
  position: relative !important;
  transform: rotate(14.53deg) !important;
  width: 6.65px !important;
}

.frame-mobile .text-wrapper-15 {
  color: var(--variable-collection-neutral-1);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 4.8px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.34px;
  position: relative;
  width: fit-content;
}

.frame-mobile .icon-chevrons-up {
  height: 6.65px !important;
  margin-right: -0.6px !important;
  position: relative !important;
  transform: rotate(14.53deg) !important;
  width: 6.65px !important;
}

.frame-mobile .div-16 {
  align-items: flex-start;
  background-color: #f6f6fa;
  border-radius: 4.09px;
  display: flex;
  flex-direction: column;
  gap: 2.73px;
  justify-content: flex-end;
  left: 5px;
  padding: 0px 0px 4.09px;
  position: absolute;
  top: 82px;
  width: 117px;
}

.frame-mobile .rectangle-5 {
  height: 73.2px;
  margin-left: -3.16px;
  margin-right: -3.69px;
  margin-top: -12.95px;
  object-fit: cover;
  position: relative;
  transform: rotate(14.53deg);
  width: 123.83px;
}

.frame-mobile .div-17 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4.09px;
  padding: 0px 4.09px;
  position: relative;
  width: 100%;
}

.frame-mobile .text-wrapper-16 {
  color: var(--variable-collection-neutral-1);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 6.1px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-right: -1.36px;
  margin-top: -0.34px;
  position: relative;
  width: 110.16px;
}

.frame-mobile .div-18 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 3.41px;
  margin-right: -1.36px;
  position: relative;
}

.frame-mobile .text-wrapper-17 {
  color: var(--variable-collection-brand-brand-primary);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 4.1px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.34px;
  position: relative;
  width: 110.16px;
}

.frame-mobile .text-wrapper-18 {
  color: var(--variable-collection-neutral-2);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 4.1px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 110.16px;
}

.frame-mobile .div-wrapper-3 {
  align-items: center;
  background-color: var(--variable-collection-neutral-white);
  border-radius: 3.07px;
  box-shadow: 0px 0px 4.43px #ff645a1a;
  display: inline-flex;
  gap: 3.41px;
  justify-content: center;
  left: 82px;
  padding: 2.73px 3.41px;
  position: absolute;
  top: 64px;
}

.frame-mobile .text-wrapper-19 {
  color: var(--variable-collection-brand-brand-primary);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 4.1px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.34px;
  position: relative;
  text-align: right;
  width: fit-content;
}

.frame-mobile .overlap-3 {
  height: 113px;
  left: 0;
  position: absolute;
  top: 164px;
  width: 128px;
}

.frame-mobile .div-19 {
  align-items: flex-start;
  background-color: #f6f6fa;
  border-radius: 4.09px;
  display: flex;
  flex-direction: column;
  gap: 2.73px;
  justify-content: flex-end;
  left: 5px;
  padding: 0px 0px 4.09px;
  position: absolute;
  top: 0;
  width: 117px;
}

.frame-mobile .div-20 {
  align-items: flex-start;
  background-color: var(--variable-collection-neutral-white);
  border-color: #3a355533;
  border-top-style: solid;
  border-top-width: 0.34px;
  display: flex;
  gap: 6.82px;
  left: 0;
  padding: 3.41px 5.46px 11.6px;
  position: absolute;
  top: 79px;
  width: 128px;
}

.frame-mobile .div-21 {
  align-items: center;
  border-radius: 2.73px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 1.36px;
  padding: 2.73px 0px;
  position: relative;
}

.frame-mobile .icon-instance-node-4 {
  height: 9.98px !important;
  margin-top: -0.9px !important;
  position: relative !important;
  transform: rotate(14.53deg) !important;
  width: 9.98px !important;
}

.frame-mobile .text-wrapper-20 {
  color: var(--variable-collection-brand-brand-primary);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 3.4px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame-mobile .div-22 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 1.36px;
  padding: 2.73px 0px;
  position: relative;
}

.frame-mobile .text-wrapper-21 {
  color: var(--variable-collection-neutral-2);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 3.4px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame-mobile .vector-2 {
  height: 4px;
  left: 88px;
  position: absolute;
  top: 36px;
  transform: rotate(14.53deg);
  width: 6px;
}

.frame-mobile .text-wrapper-22 {
  color: var(--variable-collection-neutral-1);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 6.1px;
  font-weight: 600;
  left: 5px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 68px;
}

.frame-mobile .overlap-4 {
  height: 32px;
  left: 0;
  position: absolute;
  top: 0;
  width: 128px;
}

.frame-mobile .img-2 {
  height: 15px;
  left: 50px;
  position: absolute;
  top: 14px;
  transform: rotate(14.53deg);
  width: 29px;
}

.frame-mobile .bars-status-default-2 {
  height: 15px;
  left: 0;
  position: absolute;
  top: 0;
  width: 128px;
}

.frame-mobile .container-2 {
  height: 4px;
  left: 100px;
  position: absolute;
  top: 6px;
  width: 23px;
}

.frame-mobile .overlap-group-wrapper {
  height: 4px;
  left: 14px;
  position: absolute;
  top: 0;
  width: 8px;
}

.frame-mobile .overlap-group-3 {
  height: 5px;
  position: relative;
  top: -1px;
  width: 9px;
}

.frame-mobile .img-3 {
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 8px;
}

.frame-mobile .rectangle-6 {
  background-color: var(--variable-collection-neutral-1);
  border-radius: 0.55px;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 8px;
}

.frame-mobile .combined-shape-2 {
  height: 5px;
  left: 1px;
  position: absolute;
  top: 2px;
  width: 6px;
}

.frame-mobile .wi-fi-2 {
  height: 4px;
  left: 7px;
  position: absolute;
  top: 0;
  width: 5px;
}

.frame-mobile .text-wrapper-23 {
  color: var(--variable-collection-neutral-1);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 28.4px;
  font-weight: 700;
  left: 102px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 481px;
}

.frame-mobile .div-23 {
  height: 38px;
  left: 59px;
  position: absolute;
  top: 525px;
  width: 242px;
}

.frame-mobile .text-wrapper-24 {
  color: var(--variable-collection-neutral-1);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 28.4px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 2px;
}

.frame-mobile .vector-3 {
  height: 33px;
  left: 152px;
  position: absolute;
  top: 0;
  width: 90px;
}

.frame-mobile .div-24 {
  height: 466px;
  left: 40px;
  position: absolute;
  top: 646px;
  width: 280px;
}

.frame-mobile .overlap-wrapper-2 {
  height: 321px;
  left: 0;
  position: absolute;
  top: 0;
  width: 280px;
}

.frame-mobile .overlap-5 {
  height: 321px;
  position: relative;
}

.frame-mobile .ellipse-4 {
  background-color: #ffd7d7;
  border-radius: 140px;
  height: 280px;
  left: 0;
  position: absolute;
  top: 0;
  width: 280px;
}

.frame-mobile .iphone-mini-3 {
  background-color: var(--variable-collection-neutral-white);
  border-radius: 12.15px;
  box-shadow: 0px 13.83px 35.09px #ff8e8930;
  height: 273px;
  left: 52px;
  overflow: hidden;
  position: absolute;
  top: 48px;
  width: 177px;
}

.frame-mobile .rectangle-7 {
  height: 47px;
  left: 8px;
  object-fit: cover;
  position: absolute;
  top: 71px;
  width: 47px;
}

.frame-mobile .overlap-group-4 {
  height: 56px;
  left: 0;
  position: absolute;
  top: 217px;
  width: 177px;
}

.frame-mobile .div-25 {
  background-color: #f6f6fa;
  height: 56px;
  left: 0;
  position: absolute;
  top: 0;
  width: 177px;
}

.frame-mobile .div-26 {
  align-items: center;
  background-color: #a50064;
  border-radius: 5.65px;
  display: flex;
  gap: 0.94px;
  height: 21px;
  justify-content: center;
  left: 8px;
  padding: 4.71px 11.3px;
  position: absolute;
  top: 19px;
  width: 161px;
}

.frame-mobile .image {
  height: 14.12px;
  margin-bottom: -1.41px;
  margin-top: -1.41px;
  position: relative;
  width: 14.12px;
}

.frame-mobile .text-wrapper-25 {
  color: var(--variable-collection-neutral-white);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 6.6px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 9.4px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame-mobile .text-wrapper-26 {
  color: var(--variable-collection-neutral-1);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 5.6px;
  font-weight: 600;
  left: 8px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 4px;
  width: 80px;
}

.frame-mobile .text-wrapper-27 {
  color: var(--variable-collection-brand-brand-primary);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 5.6px;
  font-weight: 600;
  left: 113px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 4px;
  width: 56px;
}

.frame-mobile .text-wrapper-28 {
  color: var(--variable-collection-brand-brand-primary);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 9.4px;
  font-weight: 700;
  left: 8px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 47px;
}

.frame-mobile .div-27 {
  height: 50px;
  left: 8px;
  position: absolute;
  top: 156px;
  width: 161px;
}

.frame-mobile .div-28 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  top: 15px;
}

.frame-mobile .div-29 {
  align-items: flex-start;
  border-bottom-style: dashed;
  border-bottom-width: 0.47px;
  border-color: #ececec;
  display: flex;
  flex: 0 0 auto;
  padding: 4.71px 0px;
  position: relative;
  width: 161.46px;
}

.frame-mobile .text-wrapper-29 {
  color: var(--variable-collection-neutral-1);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 5.6px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.47px;
  position: relative;
  width: 80.02px;
}

.frame-mobile .text-wrapper-30 {
  color: var(--variable-collection-neutral-1);
  flex: 1;
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 5.6px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.47px;
  position: relative;
  text-align: right;
}

.frame-mobile .text-wrapper-31 {
  color: var(--variable-collection-brand-brand-primary);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 5.6px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.47px;
  position: relative;
  text-align: right;
  width: 56.49px;
}

.frame-mobile .text-wrapper-32 {
  color: var(--variable-collection-neutral-2);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 5.6px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 9.4px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.frame-mobile .text-wrapper-33 {
  color: var(--variable-collection-neutral-2);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 5.6px;
  font-weight: 400;
  left: 81px;
  letter-spacing: 0;
  line-height: 9.4px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.frame-mobile .text-wrapper-34 {
  color: var(--variable-collection-neutral-2);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 5.6px;
  font-weight: 400;
  left: 152px;
  letter-spacing: 0;
  line-height: 9.4px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.frame-mobile .div-30 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5.65px;
  left: 61px;
  position: absolute;
  top: 71px;
  width: 108px;
}

.frame-mobile .text-wrapper-35 {
  color: var(--variable-collection-neutral-1);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 8.5px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-right: -0.47px;
  margin-top: -0.47px;
  position: relative;
  width: 108.27px;
}

.frame-mobile .div-31 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 1.88px;
  position: relative;
  width: 100%;
}

.frame-mobile .div-32 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.71px;
  padding: 2.82px 0px;
  position: relative;
}

.frame-mobile .icon-instance-node-5 {
  height: 4.71px !important;
  position: relative !important;
  width: 4.71px !important;
}

.frame-mobile .text-wrapper-36 {
  color: var(--variable-collection-brand-brand-primary);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 6.6px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 9.4px;
  margin-right: -5.65px;
  margin-top: -0.47px;
  position: relative;
  width: 106.85px;
}

.frame-mobile .text-wrapper-37 {
  color: var(--variable-collection-neutral-2);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 6.6px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 9.4px;
  margin-right: -5.18px;
  margin-top: -0.47px;
  position: relative;
  width: 106.38px;
}

.frame-mobile .text-wrapper-38 {
  color: var(--variable-collection-neutral-1);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 7.5px;
  font-weight: 600;
  left: 8px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 141px;
  width: 161px;
}

.frame-mobile .li-chevron-left {
  height: 9px !important;
  left: 8px !important;
  position: absolute !important;
  top: 25px !important;
  width: 9px !important;
}

.frame-mobile .text-wrapper-39 {
  color: var(--variable-collection-neutral-1);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 5.6px;
  font-weight: 600;
  left: 18px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 26px;
}

.frame-mobile .bars-status-default-3 {
  height: 21px;
  left: 0;
  position: absolute;
  top: 0;
  width: 177px;
}

.frame-mobile .time-wrapper {
  height: 10px;
  left: 9px;
  position: absolute;
  top: 7px;
  width: 32px;
}

.frame-mobile .time-2 {
  color: var(--variable-collection-neutral-1);
  font-family: "SF Pro Text-Semibold", Helvetica;
  font-size: 8.3px;
  font-weight: 400;
  left: 0;
  letter-spacing: -0.09px;
  line-height: 10px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 30px;
}

.frame-mobile .container-3 {
  height: 5px;
  left: 138px;
  position: absolute;
  top: 8px;
  width: 32px;
}

.frame-mobile .rectangle-wrapper {
  background-image: url(../../../../../static/img/rectangle-1.svg);
  background-size: 100% 100%;
  height: 5px;
  left: 20px;
  position: absolute;
  top: 0;
  width: 12px;
}

.frame-mobile .rectangle-8 {
  background-color: var(--variable-collection-neutral-1);
  border-radius: 0.75px;
  height: 6px;
  width: 10px;
}

.frame-mobile .wi-fi-3 {
  height: 5px;
  left: 10px;
  position: absolute;
  top: 0;
  width: 7px;
}

.frame-mobile .div-33 {
  height: 112px;
  left: 0;
  position: absolute;
  top: 354px;
  width: 280px;
}

.frame-mobile .div-34 {
  height: 36px;
  left: 20px;
  position: absolute;
  top: 0;
  width: 241px;
}

.frame-mobile .text-wrapper-40 {
  color: var(--variable-collection-neutral-1);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 28.3px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: -1px;
}

.frame-mobile .text-wrapper-41 {
  color: var(--variable-collection-brand-brand-primary);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 28.3px;
  font-weight: 700;
  left: 120px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: -1px;
}

.frame-mobile .text-wrapper-42 {
  color: var(--variable-collection-neutral-2);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: 21.8px;
  position: absolute;
  text-align: center;
  top: 45px;
  width: 280px;
}

.frame-mobile .div-35 {
  height: 591px;
  left: 25px;
  position: absolute;
  top: 1172px;
  width: 311px;
}

.frame-mobile .div-36 {
  height: 155px;
  left: 19px;
  position: absolute;
  top: 364px;
  width: 280px;
}

.frame-mobile .overlap-group-wrapper-2 {
  height: 321px;
  left: 19px;
  position: absolute;
  top: 12px;
  width: 280px;
}

.frame-mobile .overlap-group-5 {
  height: 321px;
  left: -34px;
  position: relative;
  top: -26px;
  width: 350px;
}

.frame-mobile .ellipse-5 {
  background-color: #d7e9ff;
  border-radius: 140px;
  height: 280px;
  left: 34px;
  position: absolute;
  top: 26px;
  width: 280px;
}

.frame-mobile .img-4 {
  height: 312px;
  left: 101px;
  position: absolute;
  top: 0;
  width: 249px;
}

.frame-mobile .img-5 {
  height: 315px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 236px;
}

.frame-mobile .div-wrapper-4 {
  align-items: center;
  background-color: var(--variable-collection-brand-brand-primary);
  border-radius: 10.21px;
  display: flex;
  gap: 1.7px;
  justify-content: center;
  left: 51px;
  padding: 10px;
  position: absolute;
  top: 541px;
  width: 217px;
}

.frame-mobile .text-wrapper-43 {
  color: var(--variable-collection-neutral-white);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  margin-top: -0.85px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame-mobile .div-37 {
  height: 442px;
  left: 26px;
  position: absolute;
  top: 1823px;
  width: 309px;
}

.frame-mobile .div-38 {
  height: 90px;
  left: 0;
  position: absolute;
  top: 352px;
  width: 309px;
}

.frame-mobile .frame-mobile-wrapper {
  height: 36px;
  left: 34px;
  position: absolute;
  top: 0;
  width: 241px;
}

.frame-mobile .div-39 {
  align-items: flex-start;
  display: inline-flex;
  gap: 8px;
  left: -34px;
  position: relative;
}

.frame-mobile .text-wrapper-44 {
  color: var(--variable-collection-neutral-1);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 28.3px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.frame-mobile .text-wrapper-45 {
  color: var(--variable-collection-brand-brand-primary);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 28.3px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.frame-mobile .text-wrapper-46 {
  color: var(--variable-collection-neutral-2);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 14px;
  letter-spacing: 0;
  line-height: 21.8px;
  position: absolute;
  text-align: center;
  top: 80px;
  width: 280px;
}

.frame-mobile .overlap-wrapper-3 {
  height: 321px;
  left: 14px;
  position: absolute;
  top: 0;
  width: 280px;
}

.frame-mobile .ellipse-6 {
  background-color: #ffead7;
  border-radius: 140px;
  height: 280px;
  left: 0;
  position: absolute;
  top: 0;
  width: 280px;
}

.frame-mobile .battery-2 {
  background-image: url(../../../../../static/img/rectangle.svg);
  background-size: 100% 100%;
  height: 5px;
  left: 20px;
  position: absolute;
  top: 0;
  width: 12px;
}

.frame-mobile .div-40 {
  height: 452px;
  left: 40px;
  position: absolute;
  top: 2325px;
  width: 280px;
}

.frame-mobile .div-41 {
  height: 90px;
  left: 0;
  position: absolute;
  top: 362px;
  width: 280px;
}

.frame-mobile .div-42 {
  align-items: flex-start;
  display: inline-flex;
  gap: 8px;
  left: 4px;
  position: relative;
}

.frame-mobile .overlap-wrapper-4 {
  height: 334px;
  left: 0;
  position: absolute;
  top: 0;
  width: 280px;
}

.frame-mobile .overlap-6 {
  height: 334px;
  position: relative;
}

.frame-mobile .iphone-mini-4 {
  background-color: var(--variable-collection-neutral-white);
  border-radius: 9.5px;
  box-shadow: 0px 10.82px 27.45px #ff8e8930;
  height: 282px;
  left: 60px;
  overflow: hidden;
  position: absolute;
  top: 52px;
  width: 162px;
}

.frame-mobile .overlap-group-6 {
  background-image: url(../../../../../static/img/rectangle-39.png);
  background-size: 100% 100%;
  height: 282px;
  position: relative;
}

.frame-mobile .overlap-7 {
  background-color: var(--variable-collection-neutral-white);
  border-radius: 5.19px;
  height: 157px;
  left: 9px;
  position: absolute;
  top: 68px;
  width: 145px;
}

.frame-mobile .text-wrapper-47 {
  color: var(--variable-collection-neutral-1);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 8.7px;
  font-weight: 700;
  left: 49px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 133px;
}

.frame-mobile .text-wrapper-48 {
  color: var(--variable-collection-neutral-3);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 7.8px;
  font-weight: 600;
  left: 16px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 108px;
}

.frame-mobile .icon-qr-code {
  height: 61px !important;
  left: 42px !important;
  position: absolute !important;
  top: 31px !important;
  width: 61px !important;
}

.frame-mobile .div-43 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 3.46px;
  left: 44px;
  position: absolute;
  top: 18px;
}

.frame-mobile .rectangle-9 {
  height: 25.97px;
  object-fit: cover;
  position: relative;
  width: 25.97px;
}

.frame-mobile .text-wrapper-49 {
  color: var(--variable-collection-neutral-white);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 7.8px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.frame-mobile .div-44 {
  align-items: center;
  background-color: var(--variable-collection-brand-brand-primary);
  border-radius: 10.65px;
  box-shadow: 0px 0px 15.38px #ff645a1a;
  display: inline-flex;
  gap: 4.73px;
  height: 52px;
  justify-content: center;
  left: 32px;
  padding: 9.46px 35.49px;
  position: absolute;
  top: 271px;
}

.frame-mobile .li-send {
  height: 18.93px !important;
  position: relative !important;
  width: 18.93px !important;
}

.frame-mobile .text-wrapper-50 {
  color: var(--variable-collection-neutral-white);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 16.6px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 23.7px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}
