.desktop {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.desktop .div {
  background-color: #ffffff;
  height: 4237px;
  overflow: hidden;
  position: relative;
  width: 1440px;
}

.desktop .overlap {
  height: 746px;
  left: 0;
  position: absolute;
  top: 3491px;
  width: 1440px;
}

.desktop .rectangle {
  background-color: var(--variable-collection-neutral-1);
  height: 414px;
  left: 0;
  position: absolute;
  top: 332px;
  width: 1440px;
}

.desktop .frame {
  border-radius: 35px;
  height: 404px;
  left: 64px;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 1312px;
}

.desktop .overlap-group {
  height: 1062px;
  position: relative;
  top: 50px;
  width: 1523px;
}

.desktop .rectangle-2 {
  background: radial-gradient(50% 50% at 50% 50%, rgb(255, 136.14, 78.63) 0%, rgb(255, 85, 76) 100%);
  border-radius: 42px;
  height: 279px;
  left: 0;
  position: absolute;
  top: 75px;
  width: 1312px;
}

.desktop .ellipse {
  background: linear-gradient(180deg, rgba(255, 153.08, 147.69, 0.04) 0%, rgba(255, 85, 76, 0) 100%);
  border-radius: 477px;
  box-shadow: inset 0px 130px 134px #ffffff26;
  height: 954px;
  left: 569px;
  position: absolute;
  top: 108px;
  width: 954px;
}

.desktop .vev-ngay-th-i-ch-chi {
  color: #ffffff;
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 52px;
  font-weight: 700;
  left: 61px;
  letter-spacing: 0;
  line-height: 81.6px;
  position: absolute;
  top: 132px;
}

.desktop .iphone-pro {
  box-shadow: 0px 18.07px 18.07px #00000040, 0px 40.04px 61.04px #00000026, 0px -18.56px 19.53px #ffffff1a,
    0px -6.35px 27.83px #0000001a;
  height: 554px;
  left: 699px;
  position: absolute;
  top: 0;
  width: 269px;
}

.desktop .overlap-2 {
  height: 554px;
  position: relative;
}

.desktop .div-2 {
  height: 554px;
  left: 3px;
  position: absolute;
  top: 0;
  width: 264px;
}

.desktop .overlap-group-2 {
  border-radius: 37.84px;
  height: 554px;
  position: relative;
}

.desktop .screen-background {
  background-color: #000000;
  border: 2.86px solid;
  border-color: #4f5179;
  border-radius: 37.84px;
  box-shadow: inset 0px 0px 0.22px 3.3px #3f3f3f;
  height: 553px;
  left: 1px;
  position: absolute;
  top: 0;
  width: 263px;
}

.desktop .stroke-body-outside {
  background-color: #00000000;
  border: 0.44px solid;
  border-color: #474970;
  border-radius: 37.84px;
  box-shadow: inset 0px 0px 0.22px 1.1px #001a27;
  height: 554px;
  left: 0;
  position: absolute;
  top: 0;
  width: 264px;
}

.desktop .stroke-body {
  background-color: #00000000;
  border: 0.88px solid;
  border-color: transparent;
  border-radius: 37.84px;
  box-shadow: inset 0px 0px 0.22px 1.1px #afb0ab;
  height: 553px;
  left: 1px;
  position: absolute;
  top: 0;
  width: 263px;
}

.desktop .screen {
  height: 344px;
  left: 10px;
  position: absolute;
  top: 10px;
  width: 244px;
}

.desktop .bottom {
  background-color: #7373a785;
  height: 4px;
  left: 53px;
  position: absolute;
  top: 550px;
  width: 4px;
}

.desktop .left-bottom {
  background-color: #7373a785;
  height: 3px;
  left: 0;
  position: absolute;
  top: 495px;
  width: 4px;
}

.desktop .left-top {
  background-color: #7373a785;
  height: 4px;
  left: 0;
  position: absolute;
  top: 56px;
  width: 4px;
}

.desktop .right-bottom {
  background-color: #7373a785;
  height: 3px;
  left: 260px;
  position: absolute;
  top: 495px;
  width: 4px;
}

.desktop .right {
  background-color: #7373a785;
  height: 4px;
  left: 260px;
  position: absolute;
  top: 56px;
  width: 4px;
}

.desktop .top {
  background-color: #7373a785;
  height: 4px;
  left: 207px;
  position: absolute;
  top: 0;
  width: 4px;
}

.desktop .buttons {
  height: 137px;
  left: 0;
  position: absolute;
  top: 107px;
  width: 269px;
}

.desktop .button {
  background-color: #4f5179;
  border: 0.33px solid;
  border-color: transparent;
  border-image: linear-gradient(
      to bottom,
      rgb(170, 170, 205),
      rgb(79, 81, 121) 25%,
      rgb(79, 81, 121) 50%,
      rgb(125, 126, 163) 71.88%,
      rgb(171, 171, 206) 93.73%
    )
    1;
  border-radius: 0.88px;
  box-shadow: inset -1.1px 0px 0px #dadbd6, inset -1.1px 0px 0.88px #9f9fc5, inset 0px -1.76px 0.44px #41423c,
    inset 0px 1.76px 0.44px #41423c, inset 0px 0.66px 0px #9f9fc5, inset 0px -0.66px 0px #9f9fc5;
  height: 43px;
  left: 0;
  position: absolute;
  top: 94px;
  width: 3px;
}

.desktop .button-2 {
  background-color: #4f5179;
  border: 0.33px solid;
  border-color: transparent;
  border-image: linear-gradient(
      to bottom,
      rgb(170, 170, 205),
      rgb(79, 81, 121) 25%,
      rgb(79, 81, 121) 50%,
      rgb(125, 126, 163) 71.88%,
      rgb(171, 171, 206) 93.73%
    )
    1;
  border-radius: 0.88px;
  box-shadow: inset -1.1px 0px 0px #dadbd6, inset -1.1px 0px 0.88px #9f9fc5, inset 0px -1.76px 0.44px #41423c,
    inset 0px 1.76px 0.44px #41423c, inset 0px 0.66px 0px #9f9fc5, inset 0px -0.66px 0px #9f9fc5;
  height: 44px;
  left: 0;
  position: absolute;
  top: 41px;
  width: 3px;
}

.desktop .silence-button {
  background-color: #4f5179;
  border: 0.33px solid;
  border-color: transparent;
  border-image: linear-gradient(
      to bottom,
      rgb(170, 170, 205),
      rgb(79, 81, 121) 25%,
      rgb(79, 81, 121) 50%,
      rgb(125, 126, 163) 71.88%,
      rgb(171, 171, 206) 93.73%
    )
    1;
  border-radius: 0.88px;
  box-shadow: inset -1.1px 0px 0px #dadbd6, inset -1.1px 0px 0.88px #9f9fc5, inset 0px -1.76px 0.44px #41423c,
    inset 0px 1.76px 0.44px #41423c, inset 0px 0.66px 0px #9f9fc5, inset 0px -0.66px 0px #9f9fc5;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 3px;
}

.desktop .power-button {
  background-color: #4f5179;
  border: 0.33px solid;
  border-color: transparent;
  border-image: linear-gradient(
      to bottom,
      rgb(170, 170, 205),
      rgb(79, 81, 121) 25%,
      rgb(79, 81, 121) 50%,
      rgb(125, 126, 163) 71.88%,
      rgb(171, 171, 206) 93.73%
    )
    1;
  border-radius: 0.88px;
  box-shadow: inset -1.1px 0px 0px #dadbd6, inset -1.1px 0px 0.88px #9f9fc5, inset 0px -1.76px 0.44px #41423c,
    inset 0px 1.76px 0.44px #41423c, inset 0px 0.66px 0px #9f9fc5, inset 0px -0.66px 0px #9f9fc5;
  height: 66px;
  left: 266px;
  position: absolute;
  top: 55px;
  width: 3px;
}

.desktop .front-camera {
  background-color: #090909;
  border-radius: 8.62px;
  height: 18px;
  left: 101px;
  position: absolute;
  top: 19px;
  width: 66px;
}

.desktop .overlap-3 {
  background-color: #090909;
  border-radius: 9.08px;
  height: 18px;
  left: 47px;
  position: relative;
  width: 18px;
}

.desktop .lens {
  background: conic-gradient(
    from 180deg at 50% 50%,
    rgb(0, 0, 0) 0deg,
    rgba(0, 0, 0, 0) 60deg,
    rgba(0, 0, 0, 0) 318.75deg
  );
  border: 0.32px solid;
  border-color: #080f20;
  border-radius: 5.2px/5.29px;
  height: 11px;
  left: 4px;
  opacity: 0.7;
  position: absolute;
  top: 4px;
  width: 10px;
}

.desktop .line {
  height: 10px;
  left: 9px;
  position: absolute;
  top: 4px;
  width: 1px;
}

.desktop .notch {
  background-color: #141414;
  border: 0.23px solid;
  border-color: #0e0e0e;
  border-radius: 6.88px;
  box-shadow: inset 0px 0px 1.15px 0.23px #000000, inset 0.69px 0px 0px #2a2a2a, inset -0.69px 0px 0px #2a2a2a;
  height: 4px;
  left: 115px;
  position: absolute;
  top: 5px;
  width: 38px;
}

.desktop .text-wrapper {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 42px;
  letter-spacing: 0;
  line-height: 18.8px;
  position: absolute;
  top: 109px;
  white-space: nowrap;
}

.desktop .pixel {
  box-shadow: 0px 4px 27px #00000040;
  height: 354px;
  left: 984px;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 242px;
}

.desktop .overlap-4 {
  height: 494px;
  position: relative;
}

.desktop .right-buttons {
  height: 137px;
  left: 240px;
  position: absolute;
  top: 118px;
  width: 2px;
}

.desktop .phone-shell {
  background-color: #0c0c0c;
  border-radius: 32.27px;
  height: 494px;
  left: 0;
  position: absolute;
  top: 0;
  width: 241px;
}

.desktop .overlap-group-3 {
  height: 492px;
  left: 2px;
  position: relative;
  width: 237px;
}

.desktop .outer-shine {
  border: 0.36px solid;
  border-color: #ffffff66;
  border-radius: 32.27px;
  filter: blur(0.73px);
  height: 490px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 236px;
}

.desktop .left-shine-stroke {
  height: 354px;
  left: 0;
  position: absolute;
  top: 0;
  width: 237px;
}

.desktop .right-shine-stroke {
  height: 350px;
  left: 2px;
  position: absolute;
  top: 4px;
  width: 232px;
}

.desktop .insert-content-here-wrapper {
  background-color: #000000;
  border-radius: 23.93px;
  height: 479px;
  left: 8px;
  position: absolute;
  top: 7px;
  width: 226px;
}

.desktop .insert-content-here {
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgb(255, 224.4, 207.19) 19.79%,
    rgb(255, 192.82, 173.19) 60.94%,
    rgb(147.95, 252.64, 214.95) 100%
  );
  border-radius: 21.39px;
  height: 470px;
  left: 4px;
  overflow: hidden;
  position: relative;
  top: 5px;
  width: 217px;
}

.desktop .img {
  height: 57px;
  left: 14px;
  position: absolute;
  top: 38px;
  width: 57px;
}

.desktop .text-wrapper-2 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 29px;
  letter-spacing: 0;
  line-height: 18.8px;
  position: absolute;
  top: 96px;
  white-space: nowrap;
}

.desktop .camera {
  background-color: #000000;
  border-radius: 7.98px;
  height: 16px;
  left: 18px;
  position: absolute;
  top: 19px;
  width: 16px;
}

.desktop .overlap-5 {
  background-color: #0b0b0b;
  border-radius: 5.8px;
  height: 12px;
  left: 2px;
  position: relative;
  top: 2px;
  width: 12px;
}

.desktop .lens-black {
  background-color: #020202;
  border-radius: 3.26px;
  height: 7px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 7px;
}

.desktop .lens-shine {
  height: 4px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 4px;
}

.desktop .appstore {
  height: 52px;
  left: 744px;
  position: absolute;
  top: 267px;
  width: 180px;
}

.desktop .googleplay {
  height: 52px;
  left: 1017px;
  position: absolute;
  top: 267px;
  width: 180px;
}

.desktop .s-n-ph-m-c-a-c-ng-ty {
  color: #ffffff;
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 94px;
  letter-spacing: 0;
  line-height: 25.1px;
  position: absolute;
  top: 554px;  
  text-align: left;

}

.desktop .text-wrapper-3 {
  color: #ffffff;
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 94px;
  letter-spacing: 0;
  line-height: 25.1px;
  position: absolute;
  top: 615px;
  white-space: nowrap;

}

.desktop .p {
  color: #ffffff;
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 901px;
  letter-spacing: 0;
  line-height: 25.1px;
  position: absolute;
  top: 621px;
  white-space: nowrap;
}

.desktop .text-wrapper-4 {
  color: #ffffff;
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25.1px;
  position: relative;
  top: 621px;
  white-space: nowrap;
  float: right;
  padding-right: 20px;
  right: 30px;
}

.desktop .text-wrapper-4 a{
  color: #ffffff;

}
.desktop .vector {
  height: 47px;
  left: 94px;
  position: absolute;
  top: 471px;
  width: 126px;
}

.desktop .overlap-6 {
  height: 1568px;
  left: 130px;
  position: absolute;
  top: -763px;
  width: 2225px;
}

.desktop .overlap-7 {
  height: 1568px;
  left: 0;
  position: absolute;
  top: 0;
  width: 2225px;
}

.desktop .overlap-8 {
  height: 1568px;
  left: 281px;
  position: absolute;
  top: 0;
  width: 1944px;
}

.desktop .ellipse-2 {
  background: radial-gradient(50% 50% at 50% 50%, rgb(255, 136.14, 78.63) 0%, rgb(255, 85, 76) 100%);
  border-radius: 915px/723px;
  height: 1446px;
  left: 114px;
  position: absolute;
  top: 0;
  width: 1830px;
}

.desktop .ellipse-3 {
  background: radial-gradient(50% 50% at 50% 50%, rgb(255, 136.14, 78.63) 0%, rgb(255, 85, 76) 100%);
  border-radius: 139.5px/52.85px;
  filter: blur(113px);
  height: 106px;
  left: 81px;
  position: absolute;
  top: 1347px;
  transform: rotate(-32.58deg);
  width: 279px;
}

.desktop .ellipse-4 {
  background-color: #ffffff1a;
  border-radius: 475px/577.64px;
  box-shadow: inset 0px -113px 48px #ffffff26;
  height: 1155px;
  left: 488px;
  position: absolute;
  top: 238px;
  transform: rotate(119.36deg);
  width: 950px;
}

.desktop .iphone-mini {
  background-color: var(--variable-collection-neutral-white);
  border-radius: 25.8px;
  box-shadow: 0px 29.39px 74.54px #ff8e8930;
  height: 594px;
  left: 660px;
  overflow: hidden;
  position: absolute;
  top: 954px;
  transform: rotate(10.47deg);
  width: 274px;
}

.desktop .frame-2 {
  align-items: flex-start;
  background-color: var(--variable-collection-neutral-white);
  border-color: #3a355533;
  border-top-style: solid;
  border-top-width: 0.73px;
  display: flex;
  gap: 14.63px;
  left: 0;
  padding: 7.32px 11.7px 24.87px;
  position: absolute;
  top: 521px;
  width: 274px;
}

.desktop .frame-3 {
  align-items: center;
  border-radius: 5.85px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 2.93px;
  padding: 5.85px 0px;
  position: relative;
}

.desktop .icon-instance-node {
  height: 20.45px !important;
  margin-top: -1.45px !important;
  position: relative !important;
  transform: rotate(-10.47deg) !important;
  width: 20.45px !important;
}

.desktop .text-wrapper-5 {
  color: var(--variable-collection-neutral-2);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 7.3px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.desktop .frame-4 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 2.93px;
  padding: 5.85px 0px;
  position: relative;
}

.desktop .text-wrapper-6 {
  color: var(--variable-collection-brand-brand-primary);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 7.3px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.desktop .text-wrapper-7 {
  color: var(--variable-collection-brand-brand-primary);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 14.6px;
  font-weight: 700;
  left: 12px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 42px;
}

.desktop .text-wrapper-8 {
  color: var(--variable-collection-neutral-1);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 11.7px;
  font-weight: 500;
  left: 12px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 75px;
}

.desktop .text-wrapper-9 {
  color: var(--variable-collection-neutral-1);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 11.7px;
  font-weight: 500;
  left: 12px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 258px;
}

.desktop .frame-5 {
  align-items: flex-start;
  background-color: #f6f6fa;
  border-radius: 8.78px;
  display: flex;
  flex-direction: column;
  gap: 5.85px;
  justify-content: flex-end;
  left: 12px;
  padding: 5.85px 5.85px 8.78px;
  position: absolute;
  top: 97px;
  width: 251px;
}

.desktop .rectangle-3 {
  height: 65.29px;
  margin-left: -3.38px;
  margin-top: -3.38px;
  object-fit: cover;
  position: relative;
  transform: rotate(-10.47deg);
  width: 65.29px;
}

.desktop .frame-6 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8.78px;
  position: relative;
  width: 100%;
}

.desktop .text-wrapper-10 {
  color: var(--variable-collection-neutral-1);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 13.2px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.73px;
  position: relative;
  width: 236.28px;
}

.desktop .frame-7 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 5.85px;
  position: relative;
  width: 100%;
}

.desktop .frame-8 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 7.32px;
  padding: 1.46px 0px;
  position: relative;
}

.desktop .icon-instance-node-2 {
  height: 8.52px !important;
  margin-bottom: -0.6px !important;
  margin-left: -0.6px !important;
  margin-right: -0.6px !important;
  margin-top: -0.6px !important;
  position: relative !important;
  transform: rotate(-10.47deg) !important;
  width: 8.52px !important;
}

.desktop .text-wrapper-11 {
  color: var(--variable-collection-brand-brand-primary);
  flex: 1;
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 8.8px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.73px;
  position: relative;
}

.desktop .text-wrapper-12 {
  color: var(--variable-collection-neutral-2);
  flex: 1;
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 8.8px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.73px;
  position: relative;
}

.desktop .div-wrapper {
  align-items: center;
  background-color: var(--variable-collection-neutral-white);
  border-radius: 6.58px;
  box-shadow: 0px 0px 9.51px #ff645a1a;
  display: inline-flex;
  gap: 7.32px;
  height: 23px;
  justify-content: center;
  left: 128px;
  padding: 5.85px 7.32px;
  position: absolute;
  top: 6px;
}

.desktop .text-wrapper-13 {
  color: var(--variable-collection-brand-brand-primary);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 8.8px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.38px;
  position: relative;
  text-align: right;
  width: fit-content;
}

.desktop .frame-9 {
  align-items: center;
  background-color: var(--variable-collection-green-1);
  border-radius: 6.58px;
  box-shadow: 0px 0px 9.51px #ff645a1a;
  display: inline-flex;
  gap: 2.93px;
  justify-content: center;
  left: 164px;
  padding: 5.85px 7.32px;
  position: absolute;
  top: 6px;
}

.desktop .icon-instance-node-3 {
  height: 13.64px !important;
  margin-bottom: -0.97px !important;
  margin-left: -0.97px !important;
  margin-top: -0.97px !important;
  position: relative !important;
  transform: rotate(-10.47deg) !important;
  width: 13.64px !important;
}

.desktop .text-wrapper-14 {
  color: var(--variable-collection-neutral-white);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 8.8px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.38px;
  position: relative;
  text-align: right;
  width: fit-content;
}

.desktop .frame-10 {
  align-items: flex-start;
  background-color: #f6f6fa;
  border-radius: 8.78px;
  display: flex;
  flex-direction: column;
  gap: 5.85px;
  justify-content: flex-end;
  left: 12px;
  padding: 5.85px 5.85px 8.78px;
  position: absolute;
  top: 280px;
  width: 251px;
}

.desktop .frame-11 {
  align-items: center;
  background-color: var(--variable-collection-neutral-white);
  border-radius: 6.58px;
  box-shadow: 0px 0px 9.51px #ff645a1a;
  display: inline-flex;
  gap: 7.32px;
  height: 23px;
  justify-content: center;
  left: 111px;
  padding: 5.85px 7.32px;
  position: absolute;
  top: 6px;
}

.desktop .frame-12 {
  align-items: center;
  background-color: var(--variable-collection-brand-brand-primary);
  border-radius: 6.58px;
  box-shadow: 0px 0px 9.51px #ff645a1a;
  display: inline-flex;
  gap: 2.93px;
  height: 23px;
  justify-content: center;
  left: 149px;
  padding: 5.85px 7.32px;
  position: absolute;
  top: 6px;
}

.desktop .bars-status-default {
  height: 32px;
  left: 0;
  position: absolute;
  top: 0;
  width: 274px;
}

.desktop .action {
  height: 18px;
  left: 15px;
  position: absolute;
  top: 10px;
  width: 56px;
}

.desktop .time {
  color: var(--variable-collection-neutral-1);
  font-family: "SF Pro Text-Semibold", Helvetica;
  font-size: 15px;
  font-weight: 400;
  left: 0;
  letter-spacing: -0.17px;
  line-height: 18px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 54px;
}

.desktop .container {
  height: 8px;
  left: 215px;
  position: absolute;
  top: 13px;
  width: 49px;
}

.desktop .battery {
  height: 8px;
  left: 31px;
  position: absolute;
  top: 0;
  width: 18px;
}

.desktop .rectangle-wrapper {
  background-image: url(../../../../../static/img/rectangle-4.svg);
  background-size: 100% 100%;
  height: 10px;
  position: relative;
  top: -1px;
}

.desktop .rectangle-4 {
  background-color: var(--variable-collection-neutral-1);
  border-radius: 1.17px;
  height: 8px;
  left: 1px;
  position: relative;
  top: 2px;
  width: 15px;
}

.desktop .combined-shape {
  height: 8px;
  left: 0;
  position: absolute;
  top: -2px;
  width: 13px;
}

.desktop .wi-fi {
  height: 8px;
  left: 17px;
  position: absolute;
  top: 0;
  width: 11px;
}

.desktop .li-clock {
  height: 20px !important;
  left: 244px !important;
  position: absolute !important;
  top: 41px !important;
  transform: rotate(-10.47deg) !important;
  width: 20px !important;
}

.desktop .iphone-mini-2 {
  background-color: var(--variable-collection-neutral-white);
  border-radius: 25.8px;
  box-shadow: 0px 29.39px 74.54px #ff8e8930;
  height: 582px;
  left: 380px;
  overflow: hidden;
  position: absolute;
  top: 869px;
  transform: rotate(-14.53deg);
  width: 269px;
}

.desktop .text-wrapper-15 {
  color: var(--variable-collection-brand-brand-primary);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 14.3px;
  font-weight: 700;
  left: 11px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 70px;
}

.desktop .frame-13 {
  background-color: #e7e8f1;
  border-radius: 43.72px;
  height: 32px;
  left: 11px;
  position: absolute;
  top: 100px;
  width: 246px;
}

.desktop .text-wrapper-16 {
  color: var(--variable-collection-neutral-1);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 10px;
  font-weight: 500;
  left: 97px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 9px;
}

.desktop .frame-14 {
  align-items: center;
  background-color: var(--variable-collection-neutral-white);
  border-radius: 43.72px;
  display: inline-flex;
  gap: 5.73px;
  height: 26px;
  left: 3px;
  padding: 0px 5.73px;
  position: absolute;
  top: 3px;
}

.desktop .frame-15 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3.58px;
  position: relative;
}

.desktop .icon-music {
  height: 13.98px !important;
  margin-bottom: -0.49px !important;
  margin-left: -1.25px !important;
  margin-top: -0.49px !important;
  position: relative !important;
  transform: rotate(14.53deg) !important;
  width: 13.98px !important;
}

.desktop .text-wrapper-17 {
  color: var(--variable-collection-neutral-1);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.72px;
  position: relative;
  width: fit-content;
}

.desktop .icon-chevrons-up {
  height: 13.98px !important;
  margin-right: -1.25px !important;
  position: relative !important;
  transform: rotate(14.53deg) !important;
  width: 13.98px !important;
}

.desktop .frame-16 {
  align-items: flex-start;
  background-color: #f6f6fa;
  border-radius: 8.6px;
  display: flex;
  flex-direction: column;
  gap: 5.73px;
  justify-content: flex-end;
  left: 11px;
  padding: 0px 0px 8.6px;
  position: absolute;
  top: 171px;
  width: 246px;
}

.desktop .rectangle-5 {
  height: 153.83px;
  margin-left: -6.64px;
  margin-right: -7.76px;
  margin-top: -27.22px;
  object-fit: cover;
  position: relative;
  transform: rotate(14.53deg);
  width: 260.24px;
}

.desktop .frame-17 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8.6px;
  padding: 0px 8.6px;
  position: relative;
  width: 100%;
}

.desktop .text-wrapper-18 {
  color: var(--variable-collection-neutral-1);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 12.9px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-right: -2.87px;
  margin-top: -0.72px;
  position: relative;
  width: 231.51px;
}

.desktop .frame-18 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 7.17px;
  margin-right: -2.87px;
  position: relative;
}

.desktop .text-wrapper-19 {
  color: var(--variable-collection-brand-brand-primary);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 8.6px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.72px;
  position: relative;
  width: 231.51px;
}

.desktop .text-wrapper-20 {
  color: var(--variable-collection-neutral-2);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 8.6px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 231.51px;
}

.desktop .frame-19 {
  align-items: center;
  background-color: var(--variable-collection-neutral-white);
  border-radius: 6.45px;
  box-shadow: 0px 0px 9.32px #ff645a1a;
  display: inline-flex;
  gap: 7.17px;
  justify-content: center;
  left: 172px;
  padding: 5.73px 7.17px;
  position: absolute;
  top: 135px;
}

.desktop .text-wrapper-21 {
  color: var(--variable-collection-brand-brand-primary);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 8.6px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.72px;
  position: relative;
  text-align: right;
  width: fit-content;
}

.desktop .overlap-9 {
  height: 237px;
  left: 0;
  position: absolute;
  top: 345px;
  width: 269px;
}

.desktop .frame-20 {
  align-items: flex-start;
  background-color: #f6f6fa;
  border-radius: 8.6px;
  display: flex;
  flex-direction: column;
  gap: 5.73px;
  justify-content: flex-end;
  left: 11px;
  padding: 0px 0px 8.6px;
  position: absolute;
  top: 0;
  width: 246px;
}

.desktop .frame-21 {
  align-items: flex-start;
  background-color: var(--variable-collection-neutral-white);
  border-color: #3a355533;
  border-top-style: solid;
  border-top-width: 0.72px;
  display: flex;
  gap: 14.33px;
  left: 0;
  padding: 7.17px 11.47px 24.37px;
  position: absolute;
  top: 164px;
  width: 269px;
}

.desktop .frame-22 {
  align-items: center;
  border-radius: 5.73px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 2.87px;
  padding: 5.73px 0px;
  position: relative;
}

.desktop .icon-instance-node-4 {
  height: 20.97px !important;
  margin-top: -1.88px !important;
  position: relative !important;
  transform: rotate(14.53deg) !important;
  width: 20.97px !important;
}

.desktop .text-wrapper-22 {
  color: var(--variable-collection-brand-brand-primary);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 7.2px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.desktop .frame-23 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 2.87px;
  padding: 5.73px 0px;
  position: relative;
}

.desktop .text-wrapper-23 {
  color: var(--variable-collection-neutral-2);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 7.2px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.desktop .vector-2 {
  height: 8px;
  left: 184px;
  position: absolute;
  top: 76px;
  transform: rotate(14.53deg);
  width: 12px;
}

.desktop .text-wrapper-24 {
  color: var(--variable-collection-neutral-1);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 12.9px;
  font-weight: 600;
  left: 11px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 143px;
}

.desktop .overlap-10 {
  height: 68px;
  left: 0;
  position: absolute;
  top: 0;
  width: 269px;
}

.desktop .frame-24 {
  height: 31px;
  left: 104px;
  position: absolute;
  top: 30px;
  transform: rotate(14.53deg);
  width: 60px;
}

.desktop .bars-status-default-2 {
  height: 32px;
  left: 0;
  position: absolute;
  top: 0;
  width: 269px;
}

.desktop .time-wrapper {
  height: 18px;
  left: 14px;
  position: absolute;
  top: 10px;
  width: 56px;
}

.desktop .container-2 {
  height: 8px;
  left: 210px;
  position: absolute;
  top: 12px;
  width: 48px;
}

.desktop .overlap-group-wrapper {
  height: 8px;
  left: 30px;
  position: absolute;
  top: 0;
  width: 18px;
}

.desktop .overlap-group-4 {
  background-image: url(../../../../../static/img/rectangle-2.svg);
  background-size: 100% 100%;
  height: 11px;
  position: relative;
  top: -1px;
}

.desktop .rectangle-6 {
  background-color: var(--variable-collection-neutral-1);
  border-radius: 1.15px;
  height: 7px;
  left: 1px;
  position: relative;
  top: 2px;
  width: 15px;
}

.desktop .combined-shape-2 {
  height: 10px;
  left: 1px;
  position: absolute;
  top: 4px;
  width: 12px;
}

.desktop .wi-fi-2 {
  height: 8px;
  left: 16px;
  position: absolute;
  top: 1px;
  width: 11px;
}

.desktop .vector-3 {
  height: 61px;
  left: 0;
  position: absolute;
  top: 1172px;
  width: 164px;
}

.desktop .text-wrapper-25 {
  color: var(--variable-collection-neutral-1);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 52px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1094px;
}

.desktop .googleplay-2 {
  height: 52px;
  left: 192px;
  position: absolute;
  top: 1287px;
  width: 180px;
}

.desktop .text-wrapper-26 {
  color: var(--variable-collection-neutral-1);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 52px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1177px;
}

.desktop .frame-25 {
  height: 32px;
  left: 0;
  position: absolute;
  top: 850px;
  width: 111px;
}

.desktop .appstore-2 {
  height: 52px;
  left: 0;
  position: absolute;
  top: 1287px;
  width: 180px;
}

.desktop .frame-26 {
  height: 164px;
  left: 120px;
  position: absolute;
  top: 1119px;
  width: 514px;
}

.desktop .text-wrapper-27 {
  color: var(--variable-collection-neutral-1);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 52px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: -1px;
}

.desktop .text-wrapper-28 {
  color: var(--variable-collection-brand-brand-primary);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 52px;
  font-weight: 700;
  left: 220px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: -1px;
}

.desktop .text-wrapper-29 {
  color: var(--variable-collection-neutral-2);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 24px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: 40px;
  position: absolute;
  top: 83px;
  width: 514px;
}

.desktop .frame-27 {
  height: 164px;
  left: 120px;
  position: absolute;
  top: 2423px;
  width: 514px;
}

.desktop .text-wrapper-30 {
  color: var(--variable-collection-brand-brand-primary);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 52px;
  font-weight: 700;
  left: 322px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: -1px;
  inline-size: max-content;

}

.desktop .frame-28 {
  height: 244px;
  left: 768px;
  position: absolute;
  top: 1670px;
  width: 514px;
}

.desktop .text-wrapper-31 {
  color: var(--variable-collection-brand-brand-primary);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 52px;
  font-weight: 700;
  left: 209px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: -1px;
}

.desktop .overlap-wrapper {
  height: 580px;
  left: 814px;
  position: absolute;
  top: 901px;
  width: 506px;
}

.desktop .overlap-11 {
  height: 580px;
  position: relative;
}

.desktop .ellipse-5 {
  background-color: #ffd7d7;
  border-radius: 253px;
  height: 506px;
  left: 0;
  position: absolute;
  top: 0;
  width: 506px;
}

.desktop .iphone-mini-3 {
  background-color: var(--variable-collection-neutral-white);
  border-radius: 21.95px;
  box-shadow: 0px 25px 63.41px #ff8e8930;
  height: 493px;
  left: 94px;
  overflow: hidden;
  position: absolute;
  top: 86px;
  width: 319px;
}

.desktop .rectangle-7 {
  height: 85px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 128px;
  width: 85px;
}

.desktop .overlap-group-5 {
  height: 100px;
  left: 0;
  position: absolute;
  top: 393px;
  width: 319px;
}

.desktop .frame-29 {
  background-color: #f6f6fa;
  height: 100px;
  left: 0;
  position: absolute;
  top: 0;
  width: 319px;
}

.desktop .frame-30 {
  align-items: center;
  background-color: #a50064;
  border-radius: 10.21px;
  display: flex;
  gap: 1.7px;
  height: 37px;
  justify-content: center;
  left: 14px;
  padding: 8.51px 20.42px;
  position: absolute;
  top: 34px;
  width: 291px;
}

.desktop .image {
  height: 25.52px;
  margin-bottom: -2.55px;
  margin-top: -2.55px;
  position: relative;
  width: 25.52px;
}

.desktop .text-wrapper-32 {
  color: var(--variable-collection-neutral-white);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 11.9px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.desktop .text-wrapper-33 {
  color: var(--variable-collection-neutral-1);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 10.2px;
  font-weight: 600;
  left: 14px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 8px;
  width: 145px;
}

.desktop .text-wrapper-34 {
  color: var(--variable-collection-brand-brand-primary);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 10.2px;
  font-weight: 600;
  left: 203px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 8px;
  width: 102px;
}

.desktop .text-wrapper-35 {
  color: var(--variable-collection-brand-brand-primary);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 17px;
  font-weight: 700;
  left: 14px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 85px;
}

.desktop .frame-31 {
  height: 91px;
  left: 14px;
  position: absolute;
  top: 282px;
  width: 292px;
}

.desktop .frame-32 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  top: 26px;
}

.desktop .frame-33 {
  align-items: flex-start;
  border-bottom-style: dashed;
  border-bottom-width: 0.85px;
  border-color: #ececec;
  display: flex;
  flex: 0 0 auto;
  padding: 8.51px 0px;
  position: relative;
  width: 291.78px;
}

.desktop .text-wrapper-36 {
  color: var(--variable-collection-neutral-1);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 10.2px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.85px;
  position: relative;
  width: 144.61px;
}

.desktop .text-wrapper-37 {
  color: var(--variable-collection-neutral-1);
  flex: 1;
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 10.2px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.85px;
  position: relative;
  text-align: right;
}

.desktop .text-wrapper-38 {
  color: var(--variable-collection-brand-brand-primary);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 10.2px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.85px;
  position: relative;
  text-align: right;
  width: 102.08px;
}

.desktop .text-wrapper-39 {
  color: var(--variable-collection-neutral-2);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 10.2px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 17px;
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.desktop .text-wrapper-40 {
  color: var(--variable-collection-neutral-2);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 10.2px;
  font-weight: 400;
  left: 147px;
  letter-spacing: 0;
  line-height: 17px;
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.desktop .text-wrapper-41 {
  color: var(--variable-collection-neutral-2);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 10.2px;
  font-weight: 400;
  left: 275px;
  letter-spacing: 0;
  line-height: 17px;
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.desktop .frame-34 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10.21px;
  left: 110px;
  position: absolute;
  top: 128px;
  width: 195px;
}

.desktop .text-wrapper-42 {
  color: var(--variable-collection-neutral-1);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 15.3px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-right: -0.85px;
  margin-top: -0.85px;
  position: relative;
  width: 195.65px;
}

.desktop .frame-35 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 3.4px;
  position: relative;
  width: 100%;
}

.desktop .frame-36 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8.51px;
  padding: 5.1px 0px;
  position: relative;
}

.desktop .icon-instance-node-5 {
  height: 8.51px !important;
  position: relative !important;
  width: 8.51px !important;
}

.desktop .text-wrapper-43 {
  color: var(--variable-collection-brand-brand-primary);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 11.9px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17px;
  margin-right: -10.21px;
  margin-top: -0.85px;
  position: relative;
  width: 193.1px;
}

.desktop .text-wrapper-44 {
  color: var(--variable-collection-neutral-2);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 11.9px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17px;
  margin-right: -9.36px;
  margin-top: -0.85px;
  position: relative;
  width: 192.25px;
}

.desktop .text-wrapper-45 {
  color: var(--variable-collection-neutral-1);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 13.6px;
  font-weight: 600;
  left: 14px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 255px;
  width: 292px;
}

.desktop .li-chevron-left {
  height: 17px !important;
  left: 15px !important;
  position: absolute !important;
  top: 46px !important;
  width: 17px !important;
}

.desktop .text-wrapper-46 {
  color: var(--variable-collection-neutral-1);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 10.2px;
  font-weight: 600;
  left: 32px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 47px;
}

.desktop .bars-status-default-3 {
  height: 37px;
  left: 0;
  position: absolute;
  top: 0;
  width: 319px;
}

.desktop .action-2 {
  height: 18px;
  left: 17px;
  position: absolute;
  top: 12px;
  width: 56px;
}

.desktop .container-3 {
  height: 10px;
  left: 250px;
  position: absolute;
  top: 15px;
  width: 57px;
}

.desktop .battery-2 {
  background-image: url(../../../../../static/img/rectangle-1.svg);
  background-size: 100% 100%;
  height: 10px;
  left: 36px;
  position: absolute;
  top: 0;
  width: 21px;
}

.desktop .rectangle-8 {
  background-color: var(--variable-collection-neutral-1);
  border-radius: 1.36px;
  height: 9px;
  left: 1px;
  position: relative;
  top: 1px;
  width: 17px;
}

.desktop .combined-shape-3 {
  height: 9px;
  left: 0;
  position: absolute;
  top: 0;
  width: 15px;
}

.desktop .wi-fi-3 {
  height: 9px;
  left: 19px;
  position: absolute;
  top: 0;
  width: 13px;
}

.desktop .frame-37 {
  height: 580px;
  left: 814px;
  position: absolute;
  top: 2205px;
  width: 506px;
}

.desktop .ellipse-6 {
  background-color: #ffead7;
  border-radius: 253px;
  height: 506px;
  left: 0;
  position: absolute;
  top: 0;
  width: 506px;
}

.desktop .battery-3 {
  background-image: url(../../../../../static/img/rectangle.svg);
  background-size: 100% 100%;
  height: 10px;
  left: 36px;
  position: absolute;
  top: 0;
  width: 21px;
}

.desktop .frame-38 {
  align-items: center;
  background-color: var(--variable-collection-brand-brand-primary);
  border-radius: 10.21px;
  display: inline-flex;
  gap: 1.7px;
  justify-content: center;
  left: 768px;
  padding: 20px;
  position: absolute;
  top: 1938px;
}

.desktop .text-wrapper-47 {
  color: var(--variable-collection-neutral-white);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  margin-top: -0.85px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.desktop .frame-39 {
  height: 580px;
  left: 120px;
  position: absolute;
  top: 1573px;
  width: 506px;
}

.desktop .overlap-12 {
  height: 580px;
  left: -61px;
  position: relative;
  top: -11px;
  width: 648px;
}

.desktop .ellipse-7 {
  background-color: #d7fffa;
  border-radius: 253px;
  height: 506px;
  left: 61px;
  position: absolute;
  top: 11px;
  width: 506px;
}

.desktop .frame-40 {
  height: 565px;
  left: 183px;
  position: absolute;
  top: 0;
  width: 466px;
}

.desktop .frame-41 {
  height: 570px;
  left: 0;
  position: absolute;
  top: 11px;
  width: 426px;
}

.desktop .frame-42 {
  height: 164px;
  left: 768px;
  position: absolute;
  top: 3025px;
  width: 552px;
}

.desktop .text-wrapper-48 {
  color: var(--variable-collection-brand-brand-primary);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 52px;
  font-weight: 700;
  left: 226px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: -1px;
}

.desktop .frame-43 {
  height: 604px;
  left: 120px;
  position: absolute;
  top: 2837px;
  width: 506px;
}

.desktop .overlap-13 {
  height: 604px;
  position: relative;
}

.desktop .iphone-mini-4 {
  background-color: var(--variable-collection-neutral-white);
  border-radius: 17.17px;
  box-shadow: 0px 19.55px 49.6px #ff8e8930;
  height: 510px;
  left: 109px;
  overflow: hidden;
  position: absolute;
  top: 94px;
  width: 293px;
}

.desktop .overlap-group-6 {
  background-image: url(../../../../../static/img/rectangle-39.png);
  background-size: 100% 100%;
  height: 510px;
  position: relative;
}

.desktop .overlap-14 {
  background-color: var(--variable-collection-neutral-white);
  border-radius: 9.39px;
  height: 283px;
  left: 16px;
  position: absolute;
  top: 124px;
  width: 262px;
}

.desktop .text-wrapper-49 {
  color: var(--variable-collection-neutral-1);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 15.6px;
  font-weight: 700;
  left: 88px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 240px;
}

.desktop .text-wrapper-50 {
  color: var(--variable-collection-neutral-3);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 14.1px;
  font-weight: 600;
  left: 28px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 196px;
}

.desktop .icon-qr-code {
  height: 110px !important;
  left: 76px !important;
  position: absolute !important;
  top: 55px !important;
  width: 110px !important;
}

.desktop .frame-44 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 6.26px;
  left: 80px;
  position: absolute;
  top: 33px;
}

.desktop .rectangle-9 {
  height: 46.93px;
  object-fit: cover;
  position: relative;
  width: 46.93px;
}

.desktop .text-wrapper-51 {
  color: var(--variable-collection-neutral-white);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 14.1px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.desktop .frame-45 {
  align-items: center;
  background-color: var(--variable-collection-brand-brand-primary);
  border-radius: 19.24px;
  box-shadow: 0px 0px 27.79px #ff645a1a;
  display: inline-flex;
  gap: 8.55px;
  height: 94px;
  justify-content: center;
  left: 57px;
  padding: 17.1px 64.14px;
  position: absolute;
  top: 489px;
}

.desktop .li-send {
  height: 34.21px !important;
  position: relative !important;
  width: 34.21px !important;
}

.desktop .text-wrapper-52 {
  color: var(--variable-collection-neutral-white);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 29.9px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 42.8px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.desktop .frame-46 {
  align-items: center;
  background-color: var(--variable-collection-brand-01);
  border-radius: 10.21px;
  display: inline-flex;
  gap: 1.7px;
  justify-content: center;
  left: 768px;
  padding: 20px;
  position: absolute;
  top: 3211px;
}

.desktop .text-wrapper-53 {
  color: var(--variable-collection-brand-brand-primary);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  margin-top: -0.85px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
