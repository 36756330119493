.policy-frame {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;

}

.policy-frame .div {
  background-color: #ffffff;
  position: relative;
  width: 100%;
}

.policy-frame .text-wrapper {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: radial-gradient(50% 50% at 50% 50%, rgb(255, 136.14, 78.63) 0%, rgb(255, 85, 76) 100%);
  background-clip: text;
  color: transparent;
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 50.2px;
  position: relative;
  text-fill-color: transparent;
  top: 155px;
  white-space: nowrap;
  text-align: center;

}

.policy-frame .img {
  height: 32px;
  left: 130px;
  position: absolute;
  top: 87px;
  width: 111px;
}

.policy-frame .text-wrapper-2 {
  color: var(--variable-collection-neutral-2);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 24px;
  font-weight: 500;
  left: 5%;
  right:  5%;
  letter-spacing: 0;
  line-height: 35px;
  position: absolute;
  top: 227px;
  padding-bottom: 250px;

}
.policy-frame .text-wrapper-2 p{
  text-wrap: pretty;
}
strong{
  font-weight: 900;
}